<template>
  <div class="container-fluid">
    <div class="row no-gutters shadow">
      <div class="col-lg-6 img-container">
        <img src="../assets/asset-1.png" class="img-fluid" />
      </div>

      <div class="col-lg-6 secondCol">
        <h1 class="title">Witaj! {{ currentUser.email }}</h1>
        <div class="sbuttons">
          <div
            :class="
              isPasswordChange === false ? 'active strike-32 last-char' : ''
            "
            @click="isPasswordChange = false"
          >
            <span :style="isPasswordChange === false ? 'font-weight:600' : ''"
              >Edycja danych</span
            >
          </div>
          <div
            :class="
              isPasswordChange === false ? '' : 'active strike-32 last-char'
            "
            @click="isPasswordChange = true"
          >
            <span :style="isPasswordChange === false ? '' : 'font-weight:600'"
              >Zmiana hasła</span
            >
          </div>
        </div>
        <form
          v-if="!isPasswordChange"
          @submit.prevent="handleSendEmail"
          class="form shadow"
        >
          <p class="form-title">Edycja danych</p>
          <label for="companyName">Subskrypcja ważna do</label>
          <div class="input-group mb-3">
            <input
              type="text"
              v-model="subscriptionDate"
              class="form-control"
              id="subscriptionDate"
              placeholder="Ważność subskrypcji"
              disabled
            />
          </div>

          <label for="companyName">Nazwa firmy</label>
          <div class="input-group mb-3">
            <input
              type="text"
              v-model="companyName"
              class="form-control"
              id="companyName"
              placeholder="Nazwa firmy"
            />
          </div>
          <label for="city">Miasto</label>
          <div class="input-group mb-3">
            <input
              id="city"
              v-model="city"
              type="text"
              class="form-control"
              name="city"
              placeholder="Podaj miasto"
            />
          </div>

          <label for="postalCode">Kod pocztowy</label>
          <div class="input-group mb-3">
            <input
              id="postalCode"
              v-model="postalCode"
              type="text"
              class="form-control"
              name="postalCode"
              placeholder="Podaj kod pocztowy"
            />
          </div>
          <label for="street">Ulica</label>
          <div class="input-group mb-3">
            <input
              id="street"
              v-model="street"
              type="text"
              class="form-control"
              name="street"
              placeholder="Podaj ulice"
            />
          </div>
          <label for="nip">NIP</label>
          <div class="input-group mb-3">
            <input
              id="nip"
              v-model="nip"
              type="text"
              class="form-control"
              name="nip"
              placeholder="Podaj NIP"
            />
          </div>

          <div class="text-right">
            <button
              type="submit"
              class="mt-4 btn btn-yellow"
              @click="handleUpdateUser()"
            >
              <span class="btn-yellow-inner" style="font-weight:bold;"
                >edytuj</span
              >
            </button>
          </div>
          <div v-if="message == 200" class="alert alert-success" role="alert">
            Pomyślnie edytowane dane
          </div>
          <div v-else-if="message" class="alert alert-danger" role="alert">
            {{ message.message }}
          </div>
        </form>
        <form v-else @submit.prevent="handleSendEmail" class="form shadow">
          <p class="form-title">Zmiana hasła</p>

          <label for="companyName">Nowe hasło</label>
          <div class="input-group mb-3">
            <input
              type="password"
              v-model="password"
              class="form-control"
              id="companyName"
              placeholder="Nowe hasło"
            />
          </div>

          <label for="companyName">Powtórz hasło</label>
          <div class="input-group mb-3">
            <input
              type="password"
              v-model="repeatPassword"
              class="form-control"
              id="companyName"
              placeholder="Powtórz hasło"
            />
          </div>

          <div class="text-right">
            <button
              type="submit"
              class="mt-4 btn btn-yellow"
              @click="handleChangePassword()"
            >
              <span class="btn-yellow-inner" style="font-weight:bold;"
                >edytuj</span
              >
            </button>
          </div>

          <div v-if="message == 200" class="alert alert-success" role="alert">
            Pomyślnie edytowane dane
          </div>
          <div v-else-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import shared from "../shared";
import UserService from "../services/user.service";

export default {
  name: "Profile",
  data() {
    return {
      companyName: "",
      city: "",
      postalCode: "",
      street: "",
      nip: "",
      message: "",
      password: "",
      repeatPassword: "",
      subscriptionDate: "",
      isPasswordChange: false,
    };
  },
  created() {
    this.$store.dispatch("user/getUsersSubscriptionExpiryDate").then(() => {
      this.mapSubscription();
    });
    this.$store.dispatch("user/getUser").then(() => {
      this.mapVuexToData();
    });
    this.formattedDate = shared.formattedDate;
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    user() {
      return this.$store.state.user.user;
    },
    subscription() {
      return this.$store.state.user.subscriptionExpiryDate;
    },
  },
  methods: {
    mapSubscription() {
      this.subscriptionDate = this.formattedDate(
        this.subscription.data.expiryDate
      );
    },
    mapVuexToData() {
      this.companyName = this.user.data.companyName;
      this.city = this.user.data.address.city;
      this.postalCode = this.user.data.address.postalCode;
      this.street = this.user.data.address.street;
      this.nip = this.user.data.nip;
    },
    handleUpdateUser() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        const { companyName, city, postalCode, street, nip, message } = this;
        UserService.updateUser({
          companyName,
          city,
          postalCode,
          street,
          nip,
          message,
        }).then(
          (response) => {
            this.message = response.status;
            setTimeout(() => (this.message = ""), 2000);
          },
          (error) => {
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            setTimeout(() => (this.message = ""), 2000);
          }
        );
      });
    },
    handleChangePassword() {
      if (
        this.password !== "" &&
        this.repeatPassword !== "" &&
        this.password === this.repeatPassword
      ) {
        UserService.updateUserPassword({
          password: this.password,
        }).then(
          (response) => {
            this.password = "";
            this.repeatPassword = "";
            this.message = response.status;
            setTimeout(() => (this.message = ""), 2000);
          },
          (error) => {
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            setTimeout(() => (this.message = ""), 2000);
          }
        );
      } else {
        this.message = "Hasła są niezgodne lub puste.";
      }
    },
  },
};
</script>

<style scoped>
@import "../assets/css/main.css";
.title {
  margin: 60px 0;
  font-size: 30px;
  text-align: center;
}
.container-fluid {
  padding: 0;
}
label {
  font-size: 0.75rem;
  font-weight: bold;
}
.sbuttons {
  font-size: 32px;
  text-align: center;
  margin: 60px 0;
}
.sbuttons div {
  margin-right: 9px;
  display: inline-block;
  cursor: pointer;
  opacity: 0.5;
}
.sbuttons .active {
  margin-right: 9px;
  display: inline-block;
  cursor: pointer;
  opacity: 1;
}
</style>
